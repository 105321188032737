import { useAppStore } from "@/stores/appStore";

export default defineNuxtRouteMiddleware(() => {
  const { app } = useAppStore();

  if (!app.settings?.hasRegistration) {
    return navigateTo("/login");
  }

  return;
});
